.popup-button.grey{
    padding: 14px;
    background: #E9E9EC;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #061A42;
    cursor: pointer;
    width: 192px;
    margin-right: 18px;
}

.popup-button.yellow {
    padding: 14px;
    background: #F5C755;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #061A42;
    cursor: pointer;
    width: 192px;
}
.popup-button.yellow.disabled {
    padding: 14px;
    background: #F5C755;
    border-radius: 12px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #061A42;
    cursor: not-allowed;
    width: 192px;
}

