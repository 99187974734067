@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
  }

.loading {
    animation: spin 1s linear infinite;
}


.count-orders-by-company-pdf-container{
    width: 90%;
    padding: 20px 30px;
    background-image: url('./assets/background.svg');
    size: landscape;
    page-break-after: always;
}

.count-orders-by-company-pdf-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    
}
.count-orders-by-company-pdf-header-route-kitchen{
    display: flex;
    align-items: center;
    gap: 16px;
justify-content: end;

}

.count-orders-by-company-pdf-header-kitchen-priority{
    width: 16px;
    height: 16px;
    background: #000000;
    border-radius: 2px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
}

.count-orders-by-company-pdf-header-route{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
}

.count-orders-by-company-pdf-header-route-name{
    color: #8F95B2;
    font-weight: 400;
    font-size: 12px;
    margin-right: 4px;
}

.count-orders-by-company-pdf-header-zone-name{
    color: black;
    font-weight: 400;
    font-size: 12px;
}


.count-orders-by-company-pdf-body{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.count-orders-by-company-pdf-body-company-name{
    font-weight: 600;
    font-size: 44px;
    margin-bottom: 20px;
}

.count-orders-by-company-pdf-body-orders-number{
    background-color: #F5C755;
    border-radius: 10px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: fit-content;
    align-items: center;
    margin-bottom: 20px;
}

.count-orders-by-company-pdf-body-orders-number-count{
    font-weight: 600;
    font-size: 40px;
}

.count-orders-by-company-pdf-address{
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 40px;
}

.count-orders-by-company-pdf-delivery-information-title{
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 16px;
}

.count-orders-by-company-pdf-delivery-information-content{
    background-color: #F8F9FC;
    font-weight: 400;
    font-size: 12px;
    padding: 20px 50px;
    border-radius: 12px;
    margin-bottom: 116px;
}

/* 
@media print {
    .count-orders-by-company-pdf-container {
      page-break-after: always;
      display: block;
    }
  } */