.tag-container {
  display: flex;
  flex-direction: row;
  background: #ebebeb;
  border-radius: 39.5px;
  padding: 7px 10px;
  width: fit-content;
  align-items: center;
  max-height: 26px;
  cursor: pointer;
}

.tag-title {
  margin-right: 12px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 7px;
  color: #1e1f20;
}
