.index-modal-header{
    display: flex;
    flex-direction: row;
}

.index-modal-header-title{
    font-family: Poppins;
font-style: normal;
font-weight: bold;
font-size: 22px;
line-height: 30px;
color: #1E1F20;
margin-left: 21px;
}

.index-modal-button-group{
    display: flex;
    flex-direction: row;
    margin-top: 40px;
}