.zone-details-form-container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 48px;
    grid-gap: 30px;
    padding: 0 60px;
}

.zone-details-input-box-container{
    background-color: white;
    border-radius: 12px;
    padding: 24px 50px;
    height: fit-content;
}

.zone-details-input-box-container-company{
    background-color: white;
    border-radius: 12px;
    padding: 24px 50px;
}

.zone-details-input-title{
    color: #061A42;
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    margin-bottom: 8px;
}