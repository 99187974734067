.order-detail-subtitle {
	color: #b7b7b7;
	font-weight: 400;
	font-size: 12px;
}

.order-detail-container {
	display: flex;
}

.order-detail-container-left {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	margin-right: 40px;
}

.order-detail-comment-container {
	margin-top: 20px;
}

.order-detail-comment {
	margin: 0 24px 22px 24px;
	font-size: 12px;
	color: #b7b7b7;
	max-width: 536px;
}

.order-detail-container-right {
	display: flex;
	flex-direction: column;
}

.order-detail-container-right-top {
	margin-bottom: 20px;
}

.order-client-information-container {
	display: flex;
	margin: 0 24px 22px 24px;
}

.order-status-container {
	margin: 0 22px 22px 22px;
	display: flex;
}