.input-tags-selected {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}

.input-tag-container {
  margin-top: 28px;
  padding: 20px;
  margin-top: 28px;
  background: rgba(127, 133, 162, 0.07);
  max-height: 220px;
  overflow: scroll;
  height: 126px;
  border-radius: 12px;
}

.add-tag-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: #286261;
  border-radius: 4px;
  padding: 4px;
  align-items: center;
  cursor: pointer;
  text-align: center;
  margin-right: 12px;
}

.add-tag-button-title {
  margin-left: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: white;
  padding-right: 12px;
}

.input-tag-wrapper {
  position: relative;
}
