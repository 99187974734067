.info-card-container{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.info-card-details-container{
    display: flex;
    margin-left: 16px;
    flex-direction: column;
}

.info-card-details-title{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.725px;
    text-transform: uppercase;
    color: #1E1F20;
}

.info-card-details-data {
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: rgba(30, 31, 32, 0.5);
}