.dashboard-card-group-container{
    background: #F8F9FB;
    box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.07);
    border-radius: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 46px;
    width: fill;
    
}