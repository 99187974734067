.notification-frequency-selects-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-top: 10px;
	margin-bottom: 20px;
	width: 100%;
}

.notification-frequency-days-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 20px;
	width: 490px;
	height: fit-content;
	background: rgba(127, 133, 162, 0.07);
	border-radius: 12px;
	overflow: auto;
	margin-top: 20px;
}

.notif-frequency-selected-item {
	background: #ebebeb;
	border-radius: 39.5px;
	padding: 10px;
	margin-right: 10px;
	margin-bottom: 10px;
	display: flex;
	flex-direction: row;
	width: fit-content;
	height: '26px';
	align-items: center;
	justify-content: space-between;
}

.notif-frequency-selected-item-text {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	line-height: 7px;
	color: #1e1f20;
}

.notif-frequency-day-selected-item {
	background: #dbeae9;
	border-radius: 39.5px;
	padding: 10px;
	margin-right: 10px;
	margin-bottom: 10px;
	display: flex;
	flex-direction: row;
	width: fit-content;
	height: fit-content;
	align-items: center;
	justify-content: space-between;
}

.notif-frequency-day-selected-item-text {
	font-family: 'Poppins';
	font-style: normal;
	font-weight: 400;
	font-size: 10px;
	line-height: 7px;
	color: #1e1f20;
	margin-right: 10px;
}
