.user-purchase-container{
    background-color: white;
    border-radius: 8px;
    padding-top: 22px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
    width: 45%;
}
.user-purchase-table-container{
    background-color: white;
    border-radius: 8px;
    width: 100%;
}


.user-purchase-title{
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 30px;
    color: #061A42;
    margin-left: 22px;
}

.user-purchase-table-header{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.725px;
    text-transform: uppercase;
    color: #1E1F20;
    padding: 14px 22px;
    text-align: left;
    border-bottom: rgba(246, 246, 248, 0.8) solid 1px;
}

.user-purchase-data{

    border-bottom: rgba(246, 246, 248, 0.8) solid 1px;
    padding: 14px 22px;
    text-align: revert;

    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 14px;
    color: rgba(30, 31, 32, 0.5)
}
.user-purchase-row:last-child td{
    border: none;
}