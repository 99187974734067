.notification-by-orders-filters{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    flex-wrap: wrap;
}


.table-action-pop-over-body{
    position: absolute;

    width: max-content;
    max-height: 200px;

    justify-content: start;

    background: #061A42;
    border-radius: 4px;
    padding-left: 12px;
    padding-right: 12px;
    top: 12px;
    z-index: 20;
    
    padding-top: 5px;
    padding-bottom: 5px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    /* overflow: scroll; */
}

.notification-by-orders-filter-items{
    height: 20px; 

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    
    text-align: start;

    color: #FFFFFF;
}

.notification-by-orders-filter-poligon{
    position: absolute;
    text-align: center;
    left: 30px;
}
