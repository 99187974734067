.company-information-vat {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
    border: 2px solid rgba(143, 146, 161, 0.2);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 15px;
    width: 100%;
}


.company-information-vat-input{
    outline: none;
    width: 100%;
    border: none;
}
.company-information-vat-input::placeholder{
    color: #7F85A2
}

.company-information-vat-be{
    color: #7F85A2;
    margin-right: 10px;
}