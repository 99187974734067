.checkbox-square-container{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.checkbox-square-icon{
    width: 18px;
    height: 18px;
    margin-right: 18px;
    cursor: pointer;
}

.checkbox-square-title{
    font-family: Poppins;
font-style: normal;
font-weight: 600;
font-size: 13px;
line-height: 19px;
/* identical to box height, or 146% */


/* dark blue */

color: #061A42;
}