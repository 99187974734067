.segments-container {
    display: flex;
    flex-direction: row;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
    border-radius: 18px;
    padding: 0 56px;
    width: fit-content;
    overflow: hidden;

}

.segment-item {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
    color: #1E1F20;
    position: relative;
    padding: 18px 0;

}

.segment-bar {
    background-color:#F5C755 ;
    height: 11.45px;
    border-radius: 114px;
    position: absolute;
    transform: translate(0, 50%);
    width: fill;
    bottom: 0;
}