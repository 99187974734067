.notifications-select-destination-type-container {
    position: relative;
    border-radius: 1.125rem;
    padding-top: 6px;
    padding-bottom: 0px;
}

.notifications-select-destination-type-content {
    background-color: white;
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    align-items: flex-start;
    line-height: 3.5rem;

    vertical-align: auto;

    position: absolute;

    width: 211px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 13px;

    margin-top: 12px;

    border: 1px solid #EEEEEE;
    border-radius: 18px;
}

.notifications-select-destination-type-tab {
    position: relative;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 8px;
}

.notifications-select-destination-type-tab-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #1E1F20;
    padding-left: 10px;
}

.notifications-select-destination-type-tab-selected {
    position: absolute;
    bottom: 0;
    left: 0;
    /* width: 100%; */
    height: 6px;
    border-radius: 6.25rem 6.25rem 0px 0px;
    background-color: #F5C755;
}

.notifications-select-destination-type-modal{
    display: flex;
    gap: 1.5rem;
    height: 100%;
    box-sizing: border-box;

    vertical-align: auto;

    position: absolute;
    left: 11.11%;
    right: 74.24%;

    width: 211px;
    height: 99px;
}

.notifications-select-destination-type-show-select{
    cursor: pointer;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;

    display: flex;
    align-items: center;

    color: #061A42;
}

.notifications-select-destination-type-down-arrow{
    margin-right: 8px;
}