.table {
	border-collapse: collapse;
	width: 100%;
}

.table thead {
	text-align: left;
}

.table thead tr {
	background-color: #f8f9fa;
}

.table thead th {
	padding: 16px 22px;
	font-weight: 600;
	font-size: 12px;
	line-height: 22px;
	color: #061a42;
}

.table tbody tr:not(:first-child) {
	border-top: 1px solid #eeeeee;
}

.table tbody td {
	padding: 35px 22px;
	font-weight: 500;
	font-size: 12px;
	line-height: 20px;
	color: #1e1f20;
	text-align: start;
}