.header-container {
	background: #ffffff;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
	border-radius: 12px;
	display: flex;
	flex-direction: row;
	align-items: center;
	min-height: 109px;
	padding: 0 40px;
	justify-content: space-between;
}
.header-title-container {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.header-title {
	font-family: Poppins;
	font-style: normal;
	font-weight: bold;
	font-size: 22px;
	color: #1e1f20;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
}

.header-action-button-container {
	display: flex;
	flex-direction: row;
}

.header-other-button-container {
	display: flex;
	flex-direction: row;
}

.header-subtitle {
	font-family: Poppins;
	font-style: normal;
	font-weight: 600;
	font-size: 13px;
	line-height: 20px;
	color: #061a42;
}
