.check-input-container{
    margin-top: 20px
}

.check-input-first-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.check-input-icon{
    margin-right: 9px;
}