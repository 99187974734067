.tags-selected {
  padding: 20px;
  margin-top: 28px;
  width: fill;
  display: wrap;
  grid-gap: 12px;
  max-height: 220px;
  height: 126px;
  overflow-y: scroll;
  background-color: rgba(127, 133, 162, 0.07);
  border-radius: 12px;
}
