.orders-header-container {
	background-color: #ffffff;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
	border-radius: 12px;
	display: flex;
	flex-direction: row;
	align-items: center;
	max-height: 109px;
	justify-content: space-between;
}

.orders-header-title {
	font-weight: bold;
	font-size: 22px;
	color: #1e1f20;
	display: flex;
	align-items: center;
	padding: 40px;
}

.orders-header-title-total-orders {
	margin-left: 6px;
	font-size: small;
	color: #f5c755;
}

.orders-time-scale-container {
	display: flex;
	height: 100%;
}

.orders-time-scale-container-container {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	position: relative;
	height: 100%;
	margin-right: 45px;
}

.orders-time-scale-container-container.selected::after {
	content: "";
	position: absolute;
	width: 100%;
	height: 6px;
	background: #f5c755;
	border-radius: 114px 114px 0px 0px;
	bottom: 0;
	left: 0;
}

.orders-time-scale-container-text {
	font-weight: 600;
	font-size: 16px;
	line-height: 22px;
}