.notification-dialog-header-bar{
    height: 1px;
    background-color: #D9D9D9;
    width: 100%;
}

.notification-dialog-body-container{
    padding: 40px;
}

.notification-dialog-body-input-title{
    font-weight: 700;
font-size: 17px;
margin-bottom: 8px;
}
.notification-dialog-body-input-description{
    font-weight: 600;
    font-size: 12px;
margin-top: 8px;
margin-bottom: 6px;
color: #9FA3B9;
}