.text-area-container {
    border: 2px solid rgba(143, 146, 161, 0.2);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 15px;
    outline: none;
    width: 100%;
}

.text-area-container::placeholder{
    color: #7F85A2
}

.text-area-container.small {
    width: 140px;
}

.text-area-container.medium {
    width: 320px;
}

.text-area-container.large {
    width: 680px;
}

.text-area-container.fill {
    width: fill;
}