.empty-states-add-button-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    background: #286261;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
    border-radius: 18px;
    padding: 10px;
    cursor: pointer;
}

.empty-states-add-button-text{
    font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
color: #FFFFFF;
margin-left: 12px;
}