.notif-timing-radio-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.publish-now-warning-text{
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 20px;
}

.date-time-pickers-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  width: 100%;
}