.toggle-container{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.toggle-ball-select-container{
    background-color: #286261;
    width: 26px;
    height: 18;
    border-radius: 20px;
    display: flex;
    padding: 4px;
    cursor: pointer;
    align-items: center;
    justify-content: flex-end;
    margin-right: 8px;

}

.toggle-ball-unselect-container{
    background-color: #B7B7B7;
    width: 26px;
    height: 18;
    border-radius: 20px;
    display: flex;
    padding: 4px;
    cursor: pointer;
    align-items: center;
    justify-content: flex-start;
    margin-right: 8px;
}
.toggle-ball-selected{
    border-radius: 100%;
    background-color: white;
    width: 15px;
    height: 15px;
}

.toggle-ball-unselected{
    border-radius: 100%;
    background-color: white;
    width: 15px;
    height: 15px;
}


.toggle-selected-text{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 13px;
    color: #286261;
}
.toggle-unselected-text{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 13px;
    color: #B7B7B7;
}
