.multi-print-button-container {
    display: flex;
    flex-direction: row;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    padding: 10px;
    padding-left: 16px;
    padding-right: 16px;
    align-items: center;
    cursor: pointer;
    position: "relative";
  }
  
  .multi-print-button-container.disabled {
    cursor: auto;
    opacity: 0.5;
  }
  
  .multi-print-button-title {
    margin-left: 12px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #061a42;
  }
  