
.promo-table-row-container{
    background-color: white;
    text-align: revert;

}

.promo-table-row-tuple.edit{
    cursor: pointer;
}
.promo-table-row-tuple{
    border: none;
    border-bottom: #EEEEEE solid 1px;
    width: 1%;
    padding: 35px
}

.promo-table-row-tuple.grey {
    font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 20px;
/* identical to box height, or 167% */


color: #7F85A2;
}

.promo-table-row-tuple-status.Active{
    width: fit-content;
    background: rgba(40, 98, 97, 0.34);
    border-radius: 6.76923px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    color: #286261;
    padding: 3px 9px;
}
.promo-table-row-tuple-status.Expired{
    width: fit-content;
    background: rgba(186, 186, 186, 0.34);
    border-radius: 6.76923px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    color: #838A8A;
    padding: 3px 9px;  
}
.promo-table-row-tuple-status.Pending{
    width: fit-content;
    background: rgba(229, 147, 104, 0.3);;
    border-radius: 6.76923px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    color: #EA7E45;
    padding: 3px 9px;  
}
