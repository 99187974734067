.notification-by-orders-filter-tag{
    background-color: #F5C755;
    padding: 4px 16px;
    border-radius: 20px;
    font-weight: 400;
    font-size: 12px;
    width: fit-content;
    white-space: nowrap;
}

.table-action-pop-over{
    position: absolute;
    z-index: 15;
    padding-left: 10px;
    top: 17px;

}

.table-action-pop-over-body-selected-container{
    position: absolute;
    top: -9px;
    left: 5px;
    /* padding-bottom: 5px; */
}

.table-action-pop-over-selected{
    position: absolute;
    z-index: 15;
    padding-left: 10px;
    padding-top: 20px;
}

.table-action-pop-over-selected{
    position: absolute;

    min-width: 93px;
    max-height: 200px;

    justify-content: start;

    background: #061A42;
    border-radius: 4px;
    padding-left: 12px;
    padding-right: 12px;
    top: 160px;
    left:51px;
    z-index: 20;
    
    padding-top: 5px;
    padding-bottom: 5px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    overflow: scroll;
}

.notification-by-orders-filter-items-selected{
    height: 20px; 

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 20px;
    /* identical to box height, or 200% */
    
    text-align: start;

    color: #FFFFFF;

    padding-left: 3px;
}


.notification-by-orders-filter-items-selected-company{
    height: 20px; 

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 20px;
    /* identical to box height, or 200% */
    padding-left: 3px;
    
    text-align: start;

    color: #FFFFFF;

    padding-bottom: 9px;
}

.notification-by-orders-filter-poligon-selected{
    position: absolute;
    text-align: center;

    padding-left: 12px;
    padding-right: 12px;
    top: 148px;
    left:54px;
    z-index: 250;
}

.notification-by-orders-filter-tag-loading{
    background-color: #F5C755;
    padding: 4px 16px;
    border-radius: 20px;
    font-weight: 400;
    font-size: 12px;
    width: fit-content;
    white-space: nowrap;
    cursor: wait;
}
