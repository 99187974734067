.company-orders-card-container{
    background-color: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    padding: 22px 28px;
    width: 280px;
    margin-bottom: 40px;
    margin-right: 48px;
}

.company-orders-card-container:last-of-type{
    margin-right: auto;
}


.company-orders-card-header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 22px;
}

.company-orders-card-header-name{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #061A42;
}

.company-orders-card-number{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 20px;
    color: #061A42;
}

.company-orders-card-actions-container{
    display: flex;
    flex-direction: row;
}