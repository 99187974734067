.notification-status-badge-container {
	padding: 3px 5px;
	min-width: 3.25rem;
	width: fit-content;
	text-align: center;
	border-radius: 7px;
	font-weight: 500;
	font-size: 10px;
	line-height: 18px;
}
