.order-client-infomration-field-container {
	display: flex;
	align-items: center;
	width: 240px;
}

.order-client-infomration-field-icon {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 14px;
	height: 14px;
}

.order-client-infomration-field-content {
	margin-left: 16px;
}

.order-client-infomration-field-content-title {
	font-weight: 600;
	font-size: 10px;
	color: #1e1f20;
	line-height: 14.5px;
	letter-spacing: 0.72px;
	text-transform: uppercase;
}

.order-client-infomration-field-content-value {
	font-weight: 300;
	font-size: 12px;
	line-height: 14.5px;
	color: rgba(30, 31, 32, 0.5);
}
