

.invitation-empty-checkbox{
    border: 2px solid rgba(143, 146, 161, 0.2);
    box-sizing: border-box;
    border-radius: 4px;
    height: 18px;
    width: 18px;
}

.invitation-mail-text{
    font-family: Poppins;
font-style: normal;
font-weight: 600;
font-size: 13px;
line-height: 19px;
color: #061A42;
margin-left: 15px;
}

.invitation-checkbox-container{
    display: flex;
    justify-content: row;
    align-items: center;
}

.invitation-mail-paraph{
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 19px;
    color: #061A42;
    margin-bottom: 28px;
    margin-top: 14px;
}