.templates-body-container {
  background: white;
  padding-top: 20px;
  border-radius: 10px;
  padding-bottom: 20px;
  width: fill;
}
.templates-table-container {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
}

.templates-table-body-container tr:last-child td {
  border: none;
}

.template-table-header-row {
  background: #f8f9fa;
}

.template-table-header-container {
  text-align: left;
}

.template-table-header-tuple {
  padding: 20px 35px;
}

.template-table-row-container {
  background-color: white;
  text-align: revert;
}

.template-table-row-tuple.edit {
  cursor: pointer;
}
.template-table-row-tuple {
  border: none;
  border-bottom: #eeeeee solid 1px;
  width: 27%;
  padding: 35px;
}
