.zone-company-drag-card-container{
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
}

.zone-company-drag-card-body{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 14px 15px;

    width: 454px;
    height: 43px;

    background: #F9F9F9;
    border-radius: 4px;
    height: fit-content;
}

.zone-company-drag-card-index{
    background-color: #061A42;
    border-radius: 6px;
    font-size: 10px;
    width: 20px;
    color: white;
    font-weight: 500;
    height: 20px;
    text-align: center;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.zone-company-drag-body-end{
    display: flex;
    align-items: center;
    gap: 8px;
}

.zone-company-drag-body-name{
    font-weight: 400;
    font-size: 14px;

    /* identical to box height, or 143% */
    color: #000000;

}