.order-table {
	border-collapse: collapse;
}

.order-table tr:not(:first-child) {
	border-top: 1px solid rgba(246, 246, 248, 0.8);
}

.order-table th {
	font-weight: 600;
	font-size: 10px;
	letter-spacing: 0.72px;
	line-height: 14.5px;
	color: #1e1f20;
	text-transform: uppercase;
	text-align: start;
	padding: 0 22px 13px 22px;
}

.order-table td {
	font-weight: 300;
	font-size: 10px;
	line-height: 14.5px;
	color: rgba(30, 31, 32, 0.5);
	padding: 14px 22px;
}
