.sidebar-container {
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 85px;
    align-items: center;
    justify-content: space-between;
    padding: 40px 0;
    z-index:1;
    overflow: scroll;
}



.menu-list-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}