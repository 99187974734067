.picture-image-container{
    width: 200px;
    height: 136px;
    position: relative;
}

.picture-image-img{
    border-radius: 12px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.picture-image-uploaded-text-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.picture-image-uploaded-text{
    font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 17px;
/* or 106% */
color: #1E1F20;

}

.picture-image-uploaded-text.green {
    color: #286261;
}


.picture-image-uploaded-container{
    border: 2px dashed rgba(159, 163, 185, 0.5);
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 17px ;
    margin-top: 26px;
}

.picture-image-delete{
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(33%, -29%);
    cursor: pointer;

}