.edit-action-menu-container {
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(130, 124, 124, 0.12);
    border-radius: 8px;
    padding: .5rem 0;
    width: 8.75rem;
    position: absolute;
    top: 2.125rem;
    left: .5rem;
    z-index: 5;
}

.edit-action-menu-row {
    padding: .375rem .75rem;
    display: flex;
    align-items: center;
    gap: .375rem;
    cursor: pointer;
}

.edit-action-menu-row:hover {
    background-color: #F8F9FA;
}

.edit-action-menu-icon-container {
    min-width: 1rem;
    height: 1rem;
}

.edit-action-menu-text {
    font-weight: 600;
    font-size: 10px;
    line-height: 15px;
    color: #061A42;
}