.popup-container{
    padding: 52px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.popup-title{
    margin-top: 55px;
    font-family: Poppins;
font-style: normal;
font-weight: bold;
font-size: 22px;
line-height: 30px;
text-align: center;
color: #1E1F20;
}

.popup-secondTitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
    color: #1E1F20;
}

.popup-subtitle {
    margin-top: 40px;
    font-family: Poppins;
font-style: normal;
font-weight: 300;
font-size: 18px;
line-height: 30px;
text-align: center;
color: #1E1F20;
text-align: center;
}

.popup-buttons-group{
    margin-top: 59px;
    display: flex;
    flex-direction: row;
    
}