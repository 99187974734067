.action-button-container{
    display: flex;
    flex-direction: column;
    position: relative;
}

.action-button{
    border-radius: 12px;
    height: 38px;
    width: 38px;
    background: #ECEEF3;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.action-button.active {
    background: #F5C755;
}

.action-button:hover {
    background: #F5C755;
}

.table-action-pop-over-bottom {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom: -41px;
    width: fill;
    z-index: 1000;
}

.table-action-pop-over-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fill;
    position: absolute;
    top: 5px;
    left: 30px;
}

.table-action-pop-over-polygon-bottom {
    height: 17px;
    width: 17px;
    transform: translateY(5px);
}

.table-action-pop-over-polygon-right {
    height: 17px;
    width: 17px;
    transform: translateX(5px) rotate(270deg);
}

.table-action-pop-over-polygon-image {
    height: 15px;
    width: 17px;
}

.table-action-pop-over-body-bottom {
    background-color: #1E3167;
    padding: 10px;
    border-radius: 12px;
    color: white;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
}


.table-action-pop-over-body-right {
    background-color: #1E3167;
    padding: 10px;
    border-radius: 12px;
    color: white;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
}

.action-button-value-container{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.action-button-value{
    color: #061A42;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
}

.tag-new-container {
    background-color: #F5C755;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 0 auto;
    width: 80%;
    border-radius: .25rem;
    position: absolute;
    bottom: -0.375rem;
    left: .25rem;
}

.tag-new-text {
    color: white;
    font-size: .5rem;
    font-weight: 700;
}

.tag-beta-text{
    color: white;
    font-size: .5rem;
    font-weight: 700;
}

.tag-beta-container {
    background-color: chocolate; 
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 0 auto;
    width: 80%;
    border-radius: .25rem;
    position: absolute;
    bottom: -0.375rem;
    left: .25rem;
}