.company-information-priority {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
    border: 2px solid rgba(143, 146, 161, 0.2);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 15px;
    width: 48px;
}


.company-information-priority-input{
    outline: none;
    width: 100%;
    border: none;
}

/* Chrome, Safari, Edge, Opera */
.company-information-priority-input::-webkit-outer-spin-button,
.company-information-priority-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.company-information-priority-input::placeholder{
    color: #7F85A2
}