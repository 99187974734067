.input-with-label-container{
    display: flex;
    flex-direction: column;
}

.input-with-label-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #9FA3B9;
    margin-bottom: 8px;
    width: fit-content;
}