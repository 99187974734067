.notif-companies-select-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin-top: 8px;
  margin-bottom: 20px;
  
}

.left-section{
  width: 220px;
  margin-top: 20px;
  margin-right: 54px;
}

.right-section{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 20px;
  width: 250px;
  height: 267px;
  background: rgba(127, 133, 162, 0.07);
  border-radius: 12px;
  overflow: auto;
}

.companies-select-radio-container{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  margin-bottom: 20px;

}