.notifications-header-container {
  background-color: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-height: 109px;
  justify-content: space-between;
}

.notifications-header-title {
  font-weight: bold;
  font-size: 22px;
  color: #1e1f20;
  display: flex;
  align-items: center;
  padding: 40px;
}

.manage-notification-templates {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: #286261;
  display: flex;
  padding: 14px;
  width: 183px;
  border-radius: 12px;
  margin-right: 40px;
  color: #ffffff;
  border: none;
  align-items: center;
  cursor: pointer;
}

.edit-icon {
}
