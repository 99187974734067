.notifications-select-type-container {
    background-color: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
    border-radius: 1.125rem;
    padding: 0 2.5rem;
}

.notifications-select-type-content {
    display: flex;
    gap: 1.5rem;
    height: 100%;
}

.notifications-select-type-tab {
    position: relative;
    height: 100%;
    cursor: pointer;
    min-width: 5.4375rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.notifications-select-type-tab-text {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: #1E1F20;
}

.notifications-select-type-tab-selected {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 6px;
    border-radius: 6.25rem 6.25rem 0px 0px;
    background-color: #F5C755;
}