.dashboard-menu-action-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 46px;
    width: fill;
}

.dashboard-menu-action-filters{
    display: flex;
    flex-direction: row;
}