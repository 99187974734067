.navigation-history-link{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #B7B7B7;
    cursor: pointer;

}

.navigation-history-container{
    width: fit-content;
    display: flex;
    align-items: center;
    height: 12px;
}

.navigation-history-separator{
    color: #B7B7B7;
    margin-left: 5px;
    margin-right: 5px;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
}