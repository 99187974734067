.save-button-container {
    background: #286261;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #FFFFFF;
    width: 130px;
    padding: 14px 0;
    cursor: pointer;
}

.save-delete-button-container{
    display: flex;
}