

.login-container{
    background-image: url('./assets/background.jpg');
    background-size: cover;
    max-width: 100%;
    max-height: 100%;
    background-repeat: no-repeat;
    width: fill;
    height: fill;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-form-container{
    background-color: white;
    box-shadow: 0px 0px 33px rgba(0, 0, 0, 0.1);
border-radius: 18px;
padding: 30px 50px;
}

.login-title{
    font-family: Poppins;
font-style: normal;
font-weight: bold;
font-size: 22px;
line-height: 30px;
color: #1E1F20;
margin-top: 20px;
margin-bottom: 5px;
}

.login-subtitle{
    font-family: Poppins;
font-style: normal;
font-weight: 300;
font-size: 18px;
line-height: 30px;
color: #1E1F20;
margin-bottom: 46px;
}

.login-button{
    background: #F5C755;
box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
border-radius: 12px;
font-family: Poppins;
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
text-align: center;
color: #061A42;
width: fill;
margin-top: 27px;
padding: 11px 0;
cursor: pointer;
}

.login-button.disabled {
    opacity: 0.7;
    cursor: not-allowed;
    pointer-events: none;
    background: #F5C755;
}

.login-footer{
    font-family: Poppins;
font-style: normal;
font-weight: 300;
font-size: 14px;
line-height: 19px;
text-align: center;
color: #9FA3B9;
width: fill;
margin-top: 30px;
}
