.input-promo-code-sub-text{
    font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 10px;
line-height: 20px;
/* identical to box height, or 200% */
display: flex;
align-items: center;
color: #7F85A2;
}

.details-input-group-container{
 display: flex; 
 flex-direction: row; 
 width: 100%; 
 justify-content: space-between;
}