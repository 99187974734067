.notifications-table-container {
	background-color: white;
	padding-top: 22px;
	border-radius: 12px;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
	margin: 36px 41px 27px 41px;
}

.notifications-table-placeholder-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}