.input-group-flex-row{
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: fill;
}

.input-group-flex-row.small {
    width: 320px;
}

.input-group-flex-row.medium {
    width: 680px;
}

.input-group-flex-row.large {
    width: 1040px;
}

.input-group-flex-row.fill {
    width: fill;
}