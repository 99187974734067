.description-parent {
  position: relative;
}

.description {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.description-tool-tips {
  background-color: #061a42;
  border-radius: 4px;
  padding: 14px;
  display: none;
  position: absolute;
  color: #ffffff;
  text-size-adjust: 8px;
  margin-top: 10px;
  font-size: 13px;
}

.description:hover + .description-tool-tips {
  display: block;
}

.polygon-description {
  position: absolute;
  left: calc(50% - 17px / 2);
  /** top: calc(50% - 25px / 2);*/
  top: -11px;
}
