.company-info-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.company-info {
  display: flex;
  flex-direction: column;
}

.company-picture {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin-left: 50px;
}