.notification-dialog-body-date-container{
    margin-top: 21px;
}
.notification-dialog-body-date-title{
    font-weight: 700;
    font-size: 17px;
    margin-bottom: 16px;
}

.notification-dialog-body-date-checkbox-container{
    display: flex;
    align-items: center;
    gap: 40px;
    ;
}

.notification-dialog-body-date-checkbox-label{
    font-weight: 500;
    font-size: 14px;
}

.notification-dialog-body-date-input-container{
    display: flex;
    gap: 40px;
    margin-top: 20px;
}

.notification-dialog-body-date-input-label{
    color: #9FA3B9;
    font-weight: 600;
    font-size: 12px;
 
}

.notification-dialog-body-date-input-group{
    display: flex;
    flex-direction: column;
    gap: 8px;
}