.notification-dialog-body-media-container{
    margin-top: 21px;
}
.notification-dialog-body-media-title{
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 8px;
    color: #9FA3B9;
}

.notification-dialog-body-media-input-container{
    display: flex;
    align-items: center;
    flex-direction: row;
    width: fit-content;
}

.notification-dialog-body-media-input-group{
    display: flex;
    gap: 40px;
}

.notification-dialog-body-media-input-label{
    font-weight: 500;
    font-size: 14px;
}