.edit-action-container {
    position: relative;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.edit-action-icon-container {
    min-width: 1.875rem;
    width: fit-content;
    height: 1.875rem;
    cursor: pointer;
    background-color: #F5C755;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
  }

.loading {
    animation: spin 1s linear infinite;
}