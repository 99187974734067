.alert-container {
    width: fit-content;
    height: fit-content;
    padding: 20px;
    background-color: #f34540;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    color: white;
    margin-bottom: 20px;
}

.success-container{
    width: fit-content;
    height: fit-content;
    padding: 20px;
    background-color: #52D8B0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    color: white;
    margin-bottom: 20px;
}