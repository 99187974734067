.data-picture-dropzone-container{
    border: 2px dashed rgba(159, 163, 185, 0.5);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 41px;
    cursor: pointer; 
}

.data-picture-dropzone-title{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #1E1F20;
    margin-top: 8px;
    margin-bottom: 9px;
}

.data-picture-dropzone-subTitle{
    font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 10px;
line-height: 15px;
/* or 153% */

text-align: center;

color: #1E1F20;
}

.data-picture-dropzone-types{
    font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 10px;
line-height: 15px;
/* or 153% */
color: rgba(30, 31, 32, 0.5);

text-align: center;
}

