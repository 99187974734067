.promo-table-header-row{
    background: #F8F9FA;
}

.promo-table-header-container{
    text-align: left;
}

.promo-table-header-tuple{
    padding: 20px 35px;
}