.promo-multi-select-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.promo-multi-select-results{
    background: rgba(127, 133, 162, 0.07);
border-radius: 12px;
}

.promo-multi-select-input-group{
    width: 43%;
}

.promo-multi-select-results-container{
    width: 43%;
    background: rgba(127, 133, 162, 0.07);
    border-radius: 12px;
    padding: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: baseline;
}