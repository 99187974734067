.notification-dialog-header-container{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 40px;
}

.notification-dialog-header-left-part{
    display: flex;
    align-items: center;
}
.notification-dialog-header-right-part{
 background-color: #286261;   
 border-radius: 12px;
 box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
 text-align: center;
 font-weight: 500;
font-size: 14px;
color: white;
padding: 14px 36px;
cursor: pointer;
}