.restaurant-card-container {
    box-shadow: 0px 2.6766px 6.69151px rgba(0, 0, 0, 0.04);
    border-radius: 8.02981px;
    max-height: 223px;
    margin-bottom: 40px;
    margin-right: 30px;
}


.restaurant-card-picture-container{
    position: relative;
    display: flex;
    min-width: 256px;
    min-height: 246px;
    max-height: 246px;

}

.restaurant-tag-B2C{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
    background: #EC7E66;
    border-radius: 6.76923px;
    padding: 3px 9px;
    margin-right: 9px;

}
.restaurant-tag-B2B{
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
    background:  #6A55EB;
    border-radius: 6.76923px;
    padding: 3px 9px;
    margin-right: 9px;
}
.restaurant-tag-group{
    position: absolute;
    top: 0;
    transform: translate(13px, 11px);
    display: flex;
    flex-direction: row;
}




.restaurant-card-action-buttons-container{
    display: flex;
    flex-direction: row;

}

.restaurant-card-footer-container{
    background-color: white;
    padding: 14px;
    display: flex;
    flex-direction: column;
    /* height: 40%; */
    transform: translateY(-100px);
    box-sizing: border-box;
    border-radius: 0 0 8px 8px;
    max-width: 254px;
}

.restaurant-card-name{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 11.3776px;
    line-height: 13px;
    color: #061A42;
    margin-bottom: 14px;
    word-wrap: break-word;
}

