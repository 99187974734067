.logout-container {
    background: #F7F7F7;
    border-radius: 12px;
    padding: 16px;
    width: fit-content;
    display: flex;
    cursor: pointer;
}

.logout-container:hover {
    background: #f13737;
}