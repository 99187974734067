.add-button-container {
  display: flex;
  flex-direction: row;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
  border-radius: 18px;
  padding: 10px;
  align-items: center;
  width: fit-content;
  cursor: pointer;
}

.add-button-title {
  margin-left: 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #286261;
}
