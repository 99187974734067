.input-price-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border: 2px solid #E9E9EC;
border-radius: 4px;
background-color: white;
padding: 15px;
}

.input-price-input{
    background-color: white;
    border: none;
    text-decoration: none;
    outline: none;
    width: fill;
}

.input-price-currency{
    font-family: Poppins;
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 20px;
color: #7F85A2;

}

.input-price-container.small {
    width: 320px;
}

.input-price-container.medium {
    width: 680px;
}

.input-price-container.large {
    width: 1040px;
}

.input-price-container.fill {
    width: fill;
}