.empty-states-container{
    display: flex;
    height: fill;
    width: fill;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.empty-states-header-title{
    font-family: Poppins;
font-style: normal;
font-weight: bold;
font-size: 22px;
line-height: 30px;
text-align: center;
color: #1E1F20;
margin-top: 40px;
}

.empty-states-header-subTitle {
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
    color: #1E1F20;
    opacity: 0.5;
    margin-bottom: 40px;
    margin-top: 3px
}
