.companies-users-card-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    padding: 23px 28px;
    width: 30%;
    align-items: center;
    margin-bottom: 40px;
    margin-right: 35px;
}

.companies-users-card-details{
    display: flex;
    flex-direction: column;
}

.companies-users-card-details-name{
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #1E1F20;
}

.companies-users-card-details-role{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #7F85A2;
}