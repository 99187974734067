.user-summary-card-container{
    background-color: white;
    border-radius: 12px;
    padding: 22px;
    width: 45%;
}

.user-summary-card-title-container{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

.user-summary-card-title{
    font-family: Poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 30px;
    color: #061A42;
}

.user-summary-card-details-group{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 31px;
}

.user-summary-card-details-container{
    margin-top: 14px;
    display: flex;
    flex-direction: column;
    width: 80%;
}
