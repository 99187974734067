.selected-item-container{
    background: #EBEBEB;
border-radius: 39.5px;
padding: 10px;
margin-right: 10px;
margin-bottom: 10px;
display: flex;
flex-direction: row;
width: fit-content;
height: fit-content;
align-items: center;
justify-content: space-between;
}

.selected-item-company-name{
    margin-right: 12px;
    font-family: Poppins;
font-style: normal;
font-weight: normal;
font-size: 10px;
line-height: 7px;

color: #1E1F20
}