.product-details-form-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.product-details-form-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}
