.dashboard-container {
    display: flex;
    flex-direction: row;
    flex: 1;
    flex-shrink: inherit;
    min-width: 100%;
    height: fill;
    max-height: 100%;
    width: fit-content;
    background-color: #F7F7F7;
}