.versioning-table-container {
	background-color: white;
	padding-top: 22px;
	border-radius: 12px;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
	margin: 27px 41px;
}

.versioning-table {
	border-collapse: collapse;
	width: 100%;
}

.versioning-table thead {
	text-align: left;
}

.versioning-table thead tr {
	background-color: #f8f9fa;
}

.versioning-table thead th {
	padding: 16px 22px;
	font-weight: 600;
	font-size: 12px;
	line-height: 22px;
	color: #061a42;
}

.versioning-table tbody tr:not(:first-child) {
	border-top: 1px solid #eeeeee;
}

.versioning-table tbody td {
	padding: 35px 22px;
	font-weight: 500;
	font-size: 12px;
	line-height: 20px;
	text-align: left;
	color: #1e1f20;
}

.versioning-table-placeholder-container {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
}