.input-date{
    outline: none;
    border: 2px solid rgba(143, 146, 161, 0.2);
    box-sizing: border-box;
    border-radius: 4px;
    padding: 15px;
    outline: none;
    width: 100%;
    font-family: Poppins;
font-style: normal;
font-weight: 300;
font-size: 14px;
line-height: 20px;
color: #7F85A2;
}

.input-date::-webkit-calendar-picker-indicator {
    filter: invert(0.7);
}

.input-date.small {
    width: 140px;
}

.input-date.medium {
    width: 320px;
}

.input-date.large {
    width: 680px;
}

.input-date.fill {
    width: fill;
}