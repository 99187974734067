.side-dish-card-container {
    flex: 1;
    height: 4rem;
    width: fill;
    border: 2px solid rgba(143, 146, 161, 0.2);
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box;
  }
  
  .side-dish-card-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    align-items: center;
  }
  
  .side-dishes-selected {
    margin-top: 28px;
    width: fill;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;
    max-height: 220px;
    overflow-y: scroll;
  }
  
  .cross-icon {
    cursor: pointer;
  }
  