.busy-indicator-div {
    font-family: poppins;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 25px;
    color: #FFFFFF;
    transform: translateX(-10px);
}

.busy-indicator-div-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}