
.promo-body-container {
    background: white;
    padding-top: 20px;
    border-radius: 10px;
    padding-bottom: 20px;
    width: fill;
}
.promo-table-container{
    border-collapse: collapse;
    width: 100%;
}

.pomo-table-body-container tr:last-child td{
    border: none;
}