.search-container {
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
    border-radius: 18px;
    display: flex;
    padding: 20px;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
}

.search-input {
    width: stretch;
    margin-left: 20px;
    outline: none;
    border: none;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
}